import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="backgroundImage">
          <div className="App-header2">
        <p className="nftTitle">Free My neighbor Alice NFT?</p>
        Ask one of the booth staff to scan your <br/>BNB Chain compatible wallet QR code
        </div>
        </div>
      </header>
    </div>
  );
}

export default App;
